<template>
  <v-card width="100%" outlined>
    <div class="pl-lg-9 pa-sm-7 pa-3 item-content">
      <div class="text-h6 font-weight-bold">Edit Accommodation</div>
      <div class="text--text">Provide general information about appartment.</div>
      <div class="font-weight-bold mb-2 mt-5">General Info</div>
      <div class="d-flex flex-md-row flex-column multi-direct">
        <v-text-field
          class="field46 width100 me-md-3"
          v-model="data.title_en"
          placeholder="Title"
          :error-messages="titleEnErrors"
          outlined
          dense
          required
          color="primary"
          height="46"
        ></v-text-field>
        <v-text-field
          class="field46 width100 ms-md-3"
          v-model="data.title_ar"
          placeholder="عنوان"
          :error-messages="titleArErrors"
          outlined
          dense
          required
          color="primary"
          height="46"
        ></v-text-field>
      </div>
      <div class="d-flex flex-md-row flex-column multi-direct">
        <v-textarea
          class="field46 width100 me-md-3"
          v-model="data.description_en"
          placeholder="Short description"
          :error-messages="descriptionEnErrors"
          auto-grow
          rows="4"
          row-height="30"
          outlined
          dense
          required
          color="primary"
        ></v-textarea>
        <v-textarea
          class="field46 width100 ms-md-3"
          v-model="data.description_ar"
          placeholder="وصف قصير"
          :error-messages="descriptionArErrors"
          auto-grow
          rows="4"
          row-height="30"
          outlined
          dense
          required
          color="primary"
        ></v-textarea>
      </div>
      <div class="font-weight-bold mb-2">Stars</div>
      <v-text-field
        class="field46 width100"
        style="max-width: 371px"
        v-model="data.stars"
        placeholder="0"
        :error-messages="starsErrors"
        type="number"
        min="3"
        max="5"
        hint="Min. 3 - Max. 5"
        persistent-hint
        maxlength="1"
        outlined
        dense
        required
        color="primary"
        height="46"
      ></v-text-field>
      <div class="font-weight-bold mb-2 mt-5">Logo/Picture</div>
      <v-card flat class="primary lighten-3 d-flex align-center justify-center" height="200" max-width="360">
        <div v-if="!image && !data.picture">
          <v-img class="mb-3" contain height="48" src="@/assets/icon/img.svg"></v-img>
          <v-btn @click="addPhoto" depressed width="116" height="30" class="primary text-body-2 rounded">Choose photo</v-btn>
        </div>
        <v-img @click="addPhoto" class="rounded-lg link" v-else contain :src="image || data.picture" height="200"></v-img>
      </v-card>
    </div>
    <v-divider></v-divider>
    <div class="text-end py-3 px-10">
      <v-btn @click="save" depressed color="primary" large width="136">Save</v-btn>
    </div>
  </v-card>
</template>

<script>
export default {
  data() {
    return {
      key: 1,
      file: '',
      image: '',
      error: [],
    };
  },
  methods: {
    notifi(btn) {
      if (btn == 'update') {
        this.$notify({
          title: 'Done!',
          message: 'Accommodation updated successfully',
          type: 'success',
          horizontalAlign: 'center',
          verticalAlign: 'top',
        });
      } else if (btn == 'doneImg') {
        this.$notify({
          title: 'Done!',
          message: 'Accommodation picture was successfully updated',
          type: 'success',
          horizontalAlign: 'center',
          verticalAlign: 'top',
        });
      }
    },
    addPhoto() {
      const input = document.createElement('input');
      const self = this;
      input.setAttribute('type', 'file');
      input.setAttribute('accept', 'image/*');
      input.addEventListener('change', function(e) {
        if (this.files && this.files[0]) {
          self.file = this.files[0];
          var reader = new FileReader();
          reader.onload = (e) => {
            self.image = e.target.result;
            self.updatePicture();
            ++self.key;
          };
          reader.readAsDataURL(this.files[0]);
        }
      });
      input.click();
    },
    async updatePicture() {
      this.error = [];
      const formData = new FormData();
      formData.append('picture', this.file);
      await this.$store
        .dispatch('setMediaAccommodation', formData)
        .then(() => {
          this.image = '';
          this.file = '';
          this.notifi('doneImg');
        })
        .catch((e) => {
          this.error = e.response.data.error;
        });
    },
    async save() {
      this.error = [];
      const data = new Object();
      data.title_en = this.data.title_en;
      data.title_ar = this.data.title_ar;
      data.description_en = this.data.description_en;
      data.description_ar = this.data.description_ar;
      data.stars = this.data.stars;
      await this.$store
        .dispatch('updateAccommodationItem', data)
        .then(() => {
          this.notifi('update');
        })
        .catch((e) => {
          this.error = e.response.data.error;
        });
    },
  },
  computed: {
    data() {
      return this.$store.getters.accommodationContent;
    },
    titleEnErrors() {
      const errors = [];
      this.error.find((item) => item == 'title_en__required') && errors.push('Please provide title');
      this.error.find((item) => item == 'title_en__invalid') && errors.push('Provided title is not valid');
      return errors;
    },
    titleArErrors() {
      const errors = [];
      this.error.find((item) => item == 'title_ar__required') && errors.push('Please provide title');
      this.error.find((item) => item == 'title_ar__invalid') && errors.push('Selected title is not valid');
      return errors;
    },
    descriptionEnErrors() {
      const errors = [];
      this.error.find((item) => item == 'description_en__required') && errors.push('Please provide description');
      this.error.find((item) => item == 'description_en__invalid') && errors.push('Provided description is not valid');
      return errors;
    },
    descriptionArErrors() {
      const errors = [];
      this.error.find((item) => item == 'description_ar__required') && errors.push('Please provide description');
      this.error.find((item) => item == 'description_ar__invalid') && errors.push('Provided description is not valid');
      return errors;
    },
    starsErrors() {
      const errors = [];
      this.error.find((item) => item == 'stars__required') && errors.push('Please provide stars');
      this.error.find((item) => item == 'stars__invalid') && errors.push('Provided stars is not valid');
      return errors;
    },
  },
};
</script>
