<template>
  <div class="settings">
    <div class="font-weight-bold">
      <router-link class="disabled--text" to="/accommodations">Accommodations</router-link>
      <v-icon color="disabled" class="text-h6">mdi-chevron-right</v-icon>
      <span class="black--text">{{ data.title_en }}</span>
    </div>
    <v-card class="mt-4" width="100%" outlined>
      <div class="d-flex wrap">
        <v-card color="item-menu py-2" flat>
          <div
            v-for="item in menuItems"
            :key="item.title"
            :class="link == item.link ? 'gray lighten-4 pl-3 pr-5' : 'px-5'"
            @click="clickMenu(item.link)"
            class="item-menu_item link"
          >
            <div>{{ item.title }}</div>
          </div>
        </v-card>
        <v-divider style="z-index: 90" vertical></v-divider>
        <div class="width100" v-if="link == 'general'">
          <General />
        </div>
        <div class="width100" v-if="link == 'plans'">
          <Plans />
        </div>
      </div>
    </v-card>
  </div>
</template>

<script>
import General from '@/components/content/General';
import Plans from '@/components/content/Plans';

export default {
  metaInfo() {
    return {
      title: this.$title(`${this.$route.name}`),
    };
  },
  components: {
    General,
    Plans,
  },
  data() {
    return {
      link: this.$route.params.tab || 'general',
      menuItems: [
        { link: 'general', title: 'General' },
        { link: 'plans', title: 'Plans' },
      ],
    };
  },
  mounted() {
    this.$store.dispatch('getAccommodationItem');
  },
  methods: {
    clickMenu(l) {
      this.link = l;
      this.$router.push(`/accommodations/${this.$route.params.accommodationId}/${l}`);
    },
  },
  computed: {
    data() {
      return this.$store.getters.accommodationContent;
    },
  },
  destroyed() {
    this.$store.dispatch('setAccommodationItem');
  },
};
</script>
