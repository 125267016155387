<template>
  <div class="accommodation-plans" :key="key">
    <v-card tile flat min-height="52" class="d-flex flex-wrap align-center" :class="!data.length ? 'ps-3' : ''">
      <v-btn
        depressed
        :style="`box-shadow: ${day == i ? '4px -2px 4px rgba(0, 0, 0, 0.08)' : '1px -1px 4px rgba(0, 0, 0, 0.04)'}; z-index: ${
          data.length - i
        }`"
        :color="day == i ? 'white' : 'gray lighten-3 text--text'"
        class="rounded-b-0 rounded-tl-0 px-7 me-n2"
        :height="52"
        @click="day = i"
        v-for="(j, i) in data"
        :key="i"
      >
        Plan {{ i + 1 }}
      </v-btn>
      <v-btn v-if="!data.find((i) => i.id == undefined)" @click="addDay" icon class="me-4 ms-6 my-4 add-btn link">
        <v-icon color="gray lighten-1">mdi-plus</v-icon>
      </v-btn>
    </v-card>
    <v-divider></v-divider>
    <v-card tile flat height="62vh" class="d-flex justify-center text-center align-center" v-if="!data.length">
      <div>
        <v-img contain height="150" src="@/assets/img/wait.svg"></v-img>
        <div class="text--text my-4">There is no plan yet</div>
        <v-btn @click="addDay" depressed width="106" height="30" color="primary" class="text-body-2 rounded">Add plan</v-btn>
      </div>
    </v-card>
    <div v-if="data.length">
      <div class="pl-lg-9 pa-sm-7 pa-3 item-content">
        <div class="text-h6 font-weight-bold">Accommodation Plan details</div>
        <div class="text--text mb-3">Provide the information in Enlish and in Arabic.</div>
        <div class="d-flex flex-md-row flex-column multi-direct">
          <v-text-field
            class="field46 width100 me-md-3"
            v-model="data[day].title_en"
            placeholder="Title"
            :error-messages="titleEnErrors"
            outlined
            dense
            required
            color="primary"
            height="46"
          ></v-text-field>
          <v-text-field
            class="field46 width100 ms-md-3"
            v-model="data[day].title_ar"
            placeholder="عنوان"
            :error-messages="titleArErrors"
            outlined
            dense
            required
            color="primary"
            height="46"
          ></v-text-field>
        </div>
        <div class="font-weight-bold mb-2">Type</div>
        <v-select
          class="field46 width100"
          style="max-width: 371px"
          v-model="data[day].type"
          placeholder="Select type"
          :error-messages="typeErrors"
          :items="typeList"
          item-text="title"
          item-value="name"
          outlined
          dense
          required
          color="primary"
          height="46"
        ></v-select>
        <div class="font-weight-bold mb-2">Number of Seats</div>
        <v-text-field
          class="field46 width100"
          style="max-width: 371px"
          v-model="data[day].seats"
          placeholder="0"
          :error-messages="seatsErrors"
          type="number"
          outlined
          dense
          required
          color="primary"
          height="46"
        ></v-text-field>
        <div class="font-weight-bold mb-2">Price</div>
        <v-text-field
          class="field46 width100"
          style="max-width: 371px"
          v-model="data[day].priceN"
          placeholder="0"
          :error-messages="priceErrors"
          suffix="SAR"
          outlined
          dense
          required
          color="primary"
          height="46"
        ></v-text-field>
      </div>
      <v-divider></v-divider>
      <div class="pl-lg-9 px-sm-7 px-3 py-3 item-content d-flex flex-wrap">
        <v-btn @click="removeDay" width="126" height="30" outlined class="rounded" color="gray">
          <span class="error--text text-body-2">Remove plan</span>
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn @click="saveDay" depressed color="primary" class="text-body-2 rounded" width="106" height="30">Save</v-btn>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      day: 0,
      key: 1,
      typeList: [
        { name: 'single', title: 'Single' },
        { name: 'double', title: 'Double' },
        { name: 'twin', title: 'Twin' },
        { name: 'triple', title: 'Triple' },
        { name: 'quad', title: 'Quad' },
        { name: 'other', title: 'Other' },
      ],
      error: [],
    };
  },
  methods: {
    notifi(btn) {
      if (btn == 'saved') {
        this.$notify({
          title: 'Done!',
          message: 'Accommodation plan added successfully',
          type: 'success',
          horizontalAlign: 'center',
          verticalAlign: 'top',
        });
      } else if (btn == 'update') {
        this.$notify({
          title: 'Done!',
          message: 'Accommodation plan updated successfully',
          type: 'success',
          horizontalAlign: 'center',
          verticalAlign: 'top',
        });
      } else if (btn == 'remove') {
        this.$notify({
          title: 'Done!',
          message: 'Accommodation plan removed',
          type: 'success',
          horizontalAlign: 'center',
          verticalAlign: 'top',
        });
      }
    },
    addDay() {
      this.data.push({});
      this.day = this.data.length - 1;
    },
    saveDay() {
      this.error = [];
      const dataRequest = this.data[this.day];
      const data = new Object();
      data.title_en = dataRequest.title_en;
      data.title_ar = dataRequest.title_ar;
      data.seats = +dataRequest.seats;
      data.type = dataRequest.type;
      data.price = dataRequest.priceN * 100;
      if (!dataRequest.id) {
        this.$store
          .dispatch('createAccommodationItemPlan', data)
          .then(() => {
            this.notifi('saved');
            ++this.key;
          })
          .catch((e) => {
            this.error = e.response.data.error;
          });
      } else {
        this.$store
          .dispatch('updateAccommodationItemPlan', { id: dataRequest.id, data: data })
          .then(() => {
            this.notifi('update');
          })
          .catch((e) => {
            this.error = e.response.data.error;
          });
      }
    },
    async removeDay() {
      const i = this.day;
      this.day == 0 ? (this.day = 0) : this.day--;
      if (this.data[i].id) {
        await this.$store.dispatch('deleteAccommodationItemPlan', this.data[i].id).then(() => {
          this.data.splice(i, 1);
        });
      } else {
        this.data.splice(i, 1);
      }
      ++this.key;
      this.notifi('remove');
    },
  },
  watch: {
    day() {
      this.error = [];
    },
  },
  computed: {
    data() {
      return this.$store.getters.accommodationContent.plans;
    },
    titleEnErrors() {
      const errors = [];
      this.error.find((item) => item == 'title_en__required') && errors.push('Please provide title');
      this.error.find((item) => item == 'title_en__invalid') && errors.push('Provided title is not valid');
      this.error.find((item) => item == 'credential__invalid') && errors.push('');
      return errors;
    },
    titleArErrors() {
      const errors = [];
      this.error.find((item) => item == 'title_ar__required') && errors.push('Please provide title');
      this.error.find((item) => item == 'title_ar__invalid') && errors.push('Provided title is not valid');
      this.error.find((item) => item == 'credential__invalid') && errors.push('');
      return errors;
    },
    typeErrors() {
      const errors = [];
      this.error.find((item) => item == 'type__required') && errors.push('Select provide type');
      this.error.find((item) => item == 'type__invalid') && errors.push('Selected type is not valid');
      this.error.find((item) => item == 'credential__invalid') && errors.push('');
      return errors;
    },
    seatsErrors() {
      const errors = [];
      this.error.find((item) => item == 'seats__required') && errors.push('Please provide seats');
      this.error.find((item) => item == 'seats__invalid') && errors.push('Provided seats is not valid');
      this.error.find((item) => item == 'credential__invalid') && errors.push('');
      return errors;
    },
    priceErrors() {
      const errors = [];
      this.error.find((item) => item == 'price__required') && errors.push('Please provide price');
      this.error.find((item) => item == 'price__invalid') && errors.push('Provided price is not valid');
      this.error.find((item) => item == 'credential__invalid') && errors.push('');
      return errors;
    },
  },
};
</script>

<style lang="scss">
.accommodation-plans {
  .add-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 20px;
    border: 1.4px solid var(--v-gray-lighten1);
    border-radius: 50px !important;
    i {
      font-size: 14px !important;
    }
  }
}
</style>
